<template>
    <div>
        <modal
            class="new-modal-default"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #f8f9fa !important; height: 30em !important;">
                <div class="v-modal-header w-100">
                    <div></div>
                    <div>
                        <span class="v-modal-dialog-title v-modal-title">OTP VERIFICATION</span>
                    </div>
                    <div></div>
                </div>
                <div class="v-modal-body overflow-hidden">
                    <div class="v-modal-layout">
                        <div v-if="emailPhoneVerify == 'primary_phone_verify'" class="my-10">
                            An OTP has been sent to your primary contact number
                        </div>
                        <div v-else-if="emailPhoneVerify == 'primary_email_verify'" class="my-10">
                            An OTP has been sent to your primary email address
                        </div>
                        <div class="d-flex align-items-center justify-content-center mb-8">
                            <span class="enterOtpText mr-5">Enter OTP Code</span>
                            <div class="otp-div">
                                <v-otp-input
                                v-if="!otpInvalid"
                                ref="otpInput"
                                input-classes="otp-input"
                                separator=""
                                :num-inputs="6"
                                :should-auto-focus="true"
                                :is-input-num="true"
                                @on-change="otpEntered"
                                @on-complete="otpEntered"
                                />
                                <v-otp-input
                                v-else
                                ref="otpInput"
                                input-classes="otp-input-warning"
                                separator=""
                                :num-inputs="6"
                                :should-auto-focus="true"
                                :is-input-num="true"
                                @on-change="otpEntered"
                                @on-complete="otpEntered"
                                />
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center flex-column mb-1" style="margin-left: 5.6em; margin-right: 7em;">
                            <span class="d-flex mb-8 w-100" :class="countdown == 0 && !otpInvalid ? 'justify-content-end' : 'justify-content-between'">
                                <span v-if="!otpInvalid && !errorMessage && !countdown == 0" style="color: #3f3f3f">Resend code in <span>{{countdown}}</span> seconds</span>
                                <span v-if="otpInvalid" style="color: #f61919;">Please enter the correct OTP</span>
                                <span v-if="!otpInvalid && errorMessage" class="text-danger" style="font-size: 14px; font-weight: 600;">{{errorMessage}}</span>
                                <span v-if="countdown == 0 || otpInvalid" style="color: #0475f3; cursor: pointer;" @click="resendVerifyCode">Resend Code</span>
                                <span v-else-if="callInProgress" style="color: #0475f3;">Resending Code...</span>
                                <span v-else style="color: #cdd2da;">Resend Code</span>
                            </span>
                        </div>
                        <!-- <div v-if="otpInvalid" class="mb-6 text-right" style="color: #e82828; font-weight: 500; font-size: 12px; margin-right: 11em">Please enter the correct OTP</div> -->
                        <div class="d-flex aligin-items-center justify-content-center mt-5">  
                            <button
                                class="btn btn-new-danger btn-smm mr-2"
                                style="box-shadow: none;width: 10rem;"
                                @click="hideSelf"
                            >CANCEL</button>      
                            <button
                                type="button"
                                class="btn btn-new-green btn-smm ml-2"
                                style="box-shadow: none;width: 10rem;"
                                @click="verify"
                            >VERIFY</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import globals from '../globals';
export default {
    data() {
        return {
            otp: "",
            countdown: 60,
            otpInvalid: false,
            errorMessage: "",
            callInProgress: false,
        }
    },
    props: ["modal_name","phone", "emailPhoneVerify", "email"],
    methods: {
        hideSelf() {
            this.clearOtpInputs();
            this.countdown = 60;
            this.otpInvalid = false;
            this.errorMessage = false;
            this.callInProgress = false;
            this.$modal.hide(this.modal_name);
            this.$emit("close");
        },
        async verify() {
            if(this.otp.length < 6) {
                this.errorMessage = 'Please enter the correct OTP';
            }
            else {
                let params = {
                    otp: this.otp,
                    login_id: this.emailPhoneVerify == 'primary_phone_verify' ? this.phone : this.email,
                    otp_for: this.emailPhoneVerify,
                    user_id: this.loggedInUser.user_id
                };
                await this.$http.post(globals.AUTH_SERVICE + "/auth/otp/verify", params).then((response)=>{
                    if (response.data.status_id == 1) {
                        this.$emit("verfiy");
                        this.hideSelf();
                    }
                }).catch((err)=>{
                    this.otpInvalid = true;
                });
            }
        },
        otpEntered(value) {
            this.otpInvalid = false;
            this.otp = value;
            this.errorMessage = '';
        },
        countDownTimer() {
            if (this.countdown > 0) {
                setTimeout(() => {
                    this.countdown -= 1;
                    this.countDownTimer();
                },1000);
            } else if (this.countdown === 0) {
                this.clearOtpInputs();
            }
        },
        clearOtpInputs() {
            this.$refs.otpInput.clearInput();
            this.otp = "";
        },
        async resendVerifyCode() {
            this.callInProgress = true;
            await this.$http.post( globals.AUTH_SERVICE + "/auth/otp", { 
                login_id: this.emailPhoneVerify == 'primary_phone_verify' ? this.phone : this.email,
                otp_for: this.emailPhoneVerify,
                user_id: this.loggedInUser.user_id
            }).then((response)=>{
                if (response.data.status_id == 1) {
                    this.errorMessage = "";
                    this.otpInvalid = false;
                    this.countdown = 60;
                    this.countDownTimer();
                    this.callInProgress = false;
                }
            }).catch((err)=>{

            });
        }
    },
    computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
    created() {
        this.countDownTimer();
    },
    watch: {
        countdown: function(val) {
            if (val == 0) {
                this.$refs.otpInput.clearInput();
                this.otpValue = "";
                this.otpInput = false;
            }
        },
    }
}
</script>