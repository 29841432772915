<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #dfe8f4 !important; height: 30em !important;">
                <img src="/static/images/parrot.svg" style="height: 10rem !important" />
                <div class="mt-5">
                    <div class="mb-4" style="font-size: 18px;">Success</div>
                    <div v-if="emailPhoneVerify == 'primary_phone_verify'">
                        Successfully verified your primary contact number and a confirmation 
                        has been sent to your number XXXXXX{{phone.slice(-4)}}
                    </div>
                    <div v-if="emailPhoneVerify == 'primary_email_verify'">
                        Successfully verified your primary email address and a confirmation 
                        has been sent to your email XXXXXX{{email.split("@")[0].slice(-2)}}@{{email.split("@")[1]}}
                    </div>
                </div>
                <div class="d-flex aligin-items-center justify-content-center mt-6">        
                    <button
                        type="button"
                        class="btn btn-new-primary btn-smm"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="hideSelf"
                    >OK</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["modal_name", "emailPhoneVerify", "phone", "email"],
    methods: {
        hideSelf() {
            this.$modal.hide(this.modal_name);
            setTimeout(() => {
                this.$emit('deactivateEditMode')
            }, 1000)
        }
    }
}
</script>